.spinner {
  position: absolute;
  height: 80vh;
  width: 100vw;
  left: 0px;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  z-index: 1;
  background-color: white;
}
.headingTag {
  background: linear-gradient(
    30deg,
    rgb(59, 130, 246) 0%,
    rgb(59, 130, 246) 0%,
    rgb(255, 42, 111) 100%
  );
  margin-right: auto;
  border-radius: var(--bs-border-radius);
  padding: 5px 10px;
}
.list {
  max-width: 800px;
  margin: auto;
}
.backgroundImage {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: -1;
  background-size: auto;
  background-repeat: no-repeat;
  background-color: lightcyan;
  background-position: left bottom;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchboxContainer {
  position: relative;
  width: 75vw;
  max-width: 500px;
}
.answer {
  max-width: 800px;
  margin: auto;
}
.suggestions {
  position: absolute;
  z-index: 2;
  box-shadow: #9597a1 0px 8px 24px;
  background: white;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  overflow: auto;
  font-size: 0.9rem;
  max-height: 500px;
}
.suggestionHeading {
  font-size: 0.8rem;
  font-weight: bold;
}
.suggestion {
  background-color: white;
  color: var(--bs-black);
  font-weight: normal;
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: none;
  position: relative;
}
.activeSuggestion,
.activeSuggestion:hover {
  background-color: #06b0c8;
  color: white;
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: none;
  position: relative;
}
.suggestionTitle {
  text-transform: uppercase;
  font-weight: bold;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.suggestionBreadcrumb {
  padding: 0.25rem;
  margin-top: 0.25rem;
  font-size: 0.8rem;
  position: absolute;
  top: 2px;
  max-width: 30%;
  right: 10px;
  background-color: #eee;
  color: #6c757d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: var(--bs-border-radius);
}
.suggestionDescription {
  margin-top: 0.25rem;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
@media (max-width: 500px) {
  .suggestionDescription {
    -webkit-line-clamp: 3;
  }
  .suggestionBreadcrumb {
    position: relative;
    top: 0;
    right: 0;
    font-size: 0.7rem;
    display: block;
    max-width: 100%;
  }
  .suggestionTitle {
    width: 100%;
  }
}
.suggestionIcon {
  max-width: 3rem;
  margin: auto;
}
.selectedSuggestion {
  font-weight: bold;
}
